<template>
  <div class="container">
    <table-page dataName="manages"
                :search="search"
                ref="table"
                @on-success="fetchSuccess"
                :request="fetchManagers">
      <template slot="button">

        <el-button type="primary"
                   class="mr-15"
                   @click="$refs.form.showModel()">新增管理员</el-button>
        <FileExport :params="exportParams">导出管理员数据</FileExport>
      </template>
      <el-table-column prop="name"
                       label="姓名">
      </el-table-column>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column label="管辖区域"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.province}}{{scope.row.city}}{{scope.row.district}}{{scope.row.net}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="net"
                       show-overflow-tooltip
                       label="是否启用">
        <template slot-scope="scope">
          <span v-if="scope.row.is_open">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>已启用
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>未启用
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="lasted_login_at"
                       show-overflow-tooltip
                       label="最后登录时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="$refs.form.showModel(scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete"
                     @click="delManager(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetManager ref="form"
                @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import SetManager from './components/SetManager'
import FileExport from '@/components/FileExport'
import { fetchManagers, delManager } from '@/api/system'
export default {
  name: 'Employees',
  data () {
    return {
      exportParams: null,
      fetchManagers,
      search: [{ type: 'area' }, {
        type: 'select',
        key: 'search_type',
        name: '搜索类型',
        value: '',
        list: [{
          value: 'NAME',
          label: '姓名'
        }, {
          value: 'TEL',
          label: '手机号码'
        }]
      }, {
        type: 'input',
        value: '',
        key: 'search_keyword',
        placeholder: '请输入关键字'
      }],
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NORMAL',
        label: '已认证'
      }, {
        value: 'PENDING',
        label: '未认证'
      }, {
        value: 'DENIED',
        label: '认证失败'
      }]
    }
  },
  components: {
    TablePage,
    SetManager,
    FileExport
  },
  methods: {
    exportEmployeeData () {
      window.open(this.exportUrl, '_blank')
    },
    delManager (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delManager({
          user_id: e.id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => {

      })
    },
    fetchSuccess (e) {
      this.exportParams = e.export
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
