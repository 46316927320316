<template>
  <el-dialog :title="form.user_id?'修改管理员':'新增管理员'"
             width="60%"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="姓名"
                        prop="name">
            <el-input v-model="form.name"
                      clearable
                      placeholder="请输入姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="手机号码"
                        prop="tel">
            <el-input v-model="form.tel"
                      clearable
                      placeholder="请输入手机号码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="登录密码"
                        prop="password">
            <el-input v-model="form.password"
                      clearable
                      show-password
                      placeholder="请输入登录密码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="管辖区域"
                        prop="province">
            <Area @change="areaChange"
                  ref="area"
                  v-model="address" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否启用">
            <el-switch v-model="form.is_open">
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="resetForm('form')">重置</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">{{form.id?'修改':'立即创建'}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Area from '@/components/Area'
import { setManager } from '@/api/system'
export default {
  data () {
    return {
      address: {
        province: '',
        city: '',
        district: '',
        net: ''
      },
      form: {
        net: '',
        tel: '',
        password: '',
        city: '',
        province: '',
        district: '',
        name: '',
        is_open: true
      },
      rules: {
        tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ],
        province: [
          { required: true, message: '请选择管辖区域，最少选择省', trigger: 'change' }
        ]

      },
      dialogVisible: false
    }
  },
  computed: {

  },
  components: {
    Area
  },
  methods: {
    areaChange (address) {
      const { form } = this
      form.province = address.province
      form.city = address.city
      form.district = address.district
      form.net = address.net
    },
    showModel (formData) {
      this.dialogVisible = true
      if (formData) {
        this.address = {
          province: formData.province,
          city: formData.city,
          district: formData.district,
          net: formData.net
        }
        this.$nextTick(() => {
          this.form = Object.assign({}, formData)
          this.$refs.form.resetFields()
        })
      } else {
        this.$nextTick(() => {
          this.resetForm()
        })
      }
    },
    submitForm (formName) {
      const { form } = this
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setManager(form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    },
    resetForm () {
      this.$refs.area.reset()
      this.$nextTick(() => {
        const { address } = this
        this.form = {
          net: address.net,
          city: address.city,
          province: address.province,
          district: address.district,
          tel: '',
          password: '',
          name: '',
          is_open: true
        }
        this.$refs.form.resetFields()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
